/**
 * @file This file contains types and functions to assist with fields which are of a dynamic type.
 *  Typically this is the case when the API has an array of values, such as advanced_filters in Policy.
 *  See advanced-helper.ts and AdvancedScope.vue for an example of usage.
 * @author Jeff Stern
 */
// Classes here are being used as POJOs so making an exception to the one class per file rule.
// eslint-disable-next-line max-classes-per-file
import {
  between,
  helpers,
  minLength,
  ValidationRule,
} from 'vuelidate/lib/validators';

/**
 * Constants to help determine what type of input we're looking at since TypeScript types are not
 * available during runtime.
 */
export enum InputType {
  select = 'select',
  text = 'text',
  number = 'number',
}

/**
 * Root value input type. Classes which extend this class describe the properties of an input, but
 * do not provide the input value itself.
 *
 * Checking the type field should allow casting to the actual input type.
 * ex:
 *  if(input.type === InputType.select) {
 *    const options = (input as SelectValueInput).options;
 *    ...
 *  }
 */
export interface ValueInput {
  readonly type: InputType;
}

export class SelectValueInput<OptionType> implements ValueInput {
  public readonly type = InputType.select;

  public options: OptionType[];

  constructor(options: OptionType[]) {
    this.options = options;
  }
}

export class TextValueInput implements ValueInput {
  public readonly type = InputType.text;

  public minimumLength: number;

  constructor(minimumLength = 0) {
    this.minimumLength = minimumLength;
  }
}

export class NumberValueInput implements ValueInput {
  public readonly type = InputType.number;

  public min: number;

  public max: number;

  public suffix: string;

  constructor(min: number, max: number, suffix: string) {
    this.min = min;
    this.max = max;
    this.suffix = suffix;
  }
}

/**
 * Helper method for adding validation rules which are specific to the input type.
 *
 * @param inputType The input type for which the validation rule applies. This should match the type
 *  of the given generic parameter.
 * @param valueInputFunc Function that retrieves a ValueInput object for the given element.
 * @param validationFunc The validation function to execute if the input is of the correct type.
 */
export const customValidator = <T extends ValueInput>(
  inputType: InputType,
  valueInputFunc: (vm) => ValueInput | null,
  validationFunc: (option: T, value, setParams) => boolean,
): ValidationRule => {
  // The typescript file for vuelidate doesn't seem to know about the closure version of withParams
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return helpers.withParams((setParams) => (value, vm) => {
    const valueInput = valueInputFunc(vm);

    if (!valueInput || valueInput.type !== inputType) {
      return true;
    }

    return validationFunc(valueInput as T, value, setParams);
  });
};

/**
 * Validation plugin for the customValidator function. This validator checks that the value is
 * between the min and max specified in the NumberValueInput object provided.
 * @param input The number input specifying the min and max values for the input.
 * @param value The value of the input.
 * @param setParams Function which sets params for use in error messages.
 */
export const numberInputIsBetween = (
  input: NumberValueInput,
  value,
  setParams,
): boolean => {
  setParams({ min: input.min });
  setParams({ max: input.max });
  return between(input.min, input.max)(value);
};

/**
 * Validation plugin for the customValidator function. This validator checks that the value is
 * at least a certain number of characters long.
 * @param input The text input specifying the minimum length for the input.
 * @param value The value of the input.
 * @param setParams Function which sets params for use in error messages.
 */
export const textHasMinLength = (
  input: TextValueInput,
  value,
  setParams,
): boolean => {
  setParams({ minLength: input.minimumLength });
  return minLength(input.minimumLength)(value);
};
