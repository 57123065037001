import { ref, SetupContext, computed } from '@vue/composition-api';
import { showStandardHttpErrorNotification } from '@/utils/util';

import { postPolicyPreview } from '@/services/policies.service';
import { Policy, PolicyConfiguration, PolicyPackage } from '@/models/policy';
import { TableHeader } from '@/types/table-options';
import {
  DEFAULT_TABLE_PAGINATION,
  TABLE_ITEMS_PER_PAGE,
} from '@/utils/constants';
import { QueryStateStorageConfig } from '@/utils/storage';

import { DataTableState } from '@/utils/data-table-state';

export const policyPreviewFooter = {
  'items-per-page-options': TABLE_ITEMS_PER_PAGE,
  'items-per-page-text': 'Packages per page:',
};

export const policyPreviewHeaders: TableHeader[] = [
  {
    text: 'OS',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: false,
    value: 'os_family',
  },
  {
    text: 'Package Name',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: false,
    value: 'display_name',
  },
  {
    text: 'Severity',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: false,
    value: 'severity',
  },
];

export function usePolicyPreview(context: SetupContext, policy: Policy) {
  const orgId = computed(() => Number(context.root.$route.query.o));
  const isLoading = ref<boolean>(false);
  const packages = ref<PolicyPackage[]>([]);
  const totalPackages = ref<number>(0);

  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-policy-advanced-preview-prefs',
    store: localStorage,
  };

  const { tableState } = DataTableState.synchronize(
    context.root.$route,
    context.root.$router,
    {
      o: { defaultValue: orgId.value },
      page: {
        defaultValue: 1,
        legacyUiValueTranslations: new Map().set(0, 1),
        apiKeyTranslation: 'p',
        legacyUiKeyTranslation: 'p',
      },
      limit: {
        defaultValue: DEFAULT_TABLE_PAGINATION,
        storeInBrowser: true,
        apiKeyTranslation: 'l',
      },
      sort_dir: {
        defaultValue: 'asc',
        excludeFromApiUrl: true,
      },
      sort_by: {
        defaultValue: 'name',
        excludeFromApiUrl: true,
      },
      type: {
        defaultValue: 'advanced',
        excludeFromApiUrl: true,
      },
      internal: {
        defaultValue: 1,
      },
    },
    {
      storageConfig,
      synchronizeApiOnly: true,
      callback: ({ apiQuery }) => getPolicyPackagePreview(apiQuery),
    },
  );

  function getPolicyPackagePreview(query: string): Promise<void> {
    isLoading.value = true;
    const { name, configuration, ...policyInfo } = policy;
    const {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      notify_reboot_user,
      notify_deferred_reboot_user,
      custom_notification_patch_message,
      custom_notification_patch_message_mac,
      custom_notification_reboot_message,
      custom_notification_reboot_message_mac,
      custom_notification_deferment_periods,
      custom_notification_max_delays,
      custom_pending_reboot_notification_message,
      custom_pending_reboot_notification_message_mac,
      custom_pending_reboot_notification_deferment_periods,
      custom_pending_reboot_notification_max_delays,
      install_deferral_enabled,
      pending_reboot_deferral_enabled,
      /* eslint-enable @typescript-eslint/no-unused-vars */
      ...policyConfig
    } = configuration as PolicyConfiguration;

    const policyPayload = new Policy({
      ...policyInfo,
      name: name || 'preview',
      configuration: policyConfig,
    });

    return postPolicyPreview(policyPayload, query)
      .then(({ results = [], size = 0 }) => {
        isLoading.value = false;
        packages.value = results;
        totalPackages.value = size;
      })
      .catch(showStandardHttpErrorNotification);
  }

  return { isLoading, packages, totalPackages, tableState };
}
