import { required, requiredIf } from 'vuelidate/lib/validators';
import i18n from '@/i18n';
import {
  PolicyConfiguration,
  PolicyPatchRule,
  PolicyAdvancedCondition,
  PolicyAdvancedLeft,
  PolicyAdvancedRight,
} from '@/models/policy';
import {
  customValidator,
  InputType,
  numberInputIsBetween,
  NumberValueInput,
  SelectValueInput,
  textHasMinLength,
  TextValueInput,
  ValueInput,
} from '@/utils/dynamic-inputs';

export type ConditionalOption = {
  name: string;
  value: PolicyAdvancedCondition;
};

export type RightOption = {
  name: string;
  value: PolicyAdvancedRight;
};

export type AdvancedOption = {
  name: string;
  value: PolicyAdvancedLeft;
  conditional: ConditionalOption[];
  valueInput: ValueInput;
};

// Conditionals
export const conditionalIsOptions: ConditionalOption[] = [
  {
    name: i18n.ts('policy.packageTargeting.conditionals.is'),
    value: PolicyAdvancedCondition.is,
  },
  {
    name: i18n.ts('policy.packageTargeting.conditionals.isNot'),
    value: PolicyAdvancedCondition.is_not,
  },
];

export const conditionalComparableOptions: ConditionalOption[] = [
  {
    name: i18n.ts('policy.packageTargeting.conditionals.is'),
    value: PolicyAdvancedCondition.is,
  },
  {
    name: i18n.ts('policy.packageTargeting.conditionals.isNot'),
    value: PolicyAdvancedCondition.is_not,
  },
  {
    name: i18n.ts('policy.packageTargeting.conditionals.lessOrEqual'),
    value: PolicyAdvancedCondition.less_than_or_equal,
  },
  {
    name: i18n.ts('policy.packageTargeting.conditionals.greaterOrEqual'),
    value: PolicyAdvancedCondition.greater_than_or_equal,
  },
];

export const conditionalPatchAgeOptions: ConditionalOption[] = [
  {
    name: i18n.ts('policy.packageTargeting.conditionals.greaterOrEqual'),
    value: PolicyAdvancedCondition.greater_than_or_equal,
  },
];

export const conditionalContainsOptions: ConditionalOption[] = [
  {
    name: i18n.ts('policy.packageTargeting.conditionals.contains'),
    value: PolicyAdvancedCondition.contains,
  },
  {
    name: i18n.ts('policy.packageTargeting.conditionals.doesNotContain'),
    value: PolicyAdvancedCondition.does_not_contain,
  },
];

// Right Options
export const patchSourceOptions: RightOption[] = [
  {
    name: i18n.ts('policy.packageTargeting.sourceOptions.windowsUpdate'),
    value: PolicyAdvancedRight.windowsupdate,
  },
  {
    name: i18n.ts('policy.packageTargeting.sourceOptions.microsoft'),
    value: PolicyAdvancedRight.microsoft,
  },
  {
    name: i18n.ts('policy.packageTargeting.sourceOptions.apple'),
    value: PolicyAdvancedRight.apple,
  },
  {
    name: i18n.ts('policy.packageTargeting.sourceOptions.oracle'),
    value: PolicyAdvancedRight.oracle,
  },
  {
    name: i18n.ts('policy.packageTargeting.sourceOptions.mozilla'),
    value: PolicyAdvancedRight.mozilla,
  },
  {
    name: i18n.ts('policy.packageTargeting.sourceOptions.adobe'),
    value: PolicyAdvancedRight.adobe,
  },
  {
    name: i18n.ts('policy.packageTargeting.sourceOptions.opera'),
    value: PolicyAdvancedRight.opera,
  },
];

export const patchOSOptions: RightOption[] = [
  {
    name: i18n.ts('policy.packageTargeting.osOptions.windows'),
    value: PolicyAdvancedRight.windows,
  },
  {
    name: i18n.ts('policy.packageTargeting.osOptions.mac'),
    value: PolicyAdvancedRight.mac,
  },
  {
    name: i18n.ts('policy.packageTargeting.osOptions.linux'),
    value: PolicyAdvancedRight.linux,
  },
];

export const typeWindowsOnlyOptions: RightOption[] = [
  {
    name: i18n.ts('policy.packageTargeting.typeOptions.application'),
    value: PolicyAdvancedRight.application,
  },
  {
    name: i18n.ts('policy.packageTargeting.typeOptions.connectors'),
    value: PolicyAdvancedRight.connectors,
  },
  {
    name: i18n.ts('policy.packageTargeting.typeOptions.criticalUpdates'),
    value: PolicyAdvancedRight.critical_updates,
  },
  {
    name: i18n.ts('policy.packageTargeting.typeOptions.definitionUpdates'),
    value: PolicyAdvancedRight.definition_updates,
  },
  {
    name: i18n.ts('policy.packageTargeting.typeOptions.developerKits'),
    value: PolicyAdvancedRight.developer_kits,
  },
  {
    name: i18n.ts('policy.packageTargeting.typeOptions.featurePacks'),
    value: PolicyAdvancedRight.feature_packs,
  },
  {
    name: i18n.ts('policy.packageTargeting.typeOptions.guidance'),
    value: PolicyAdvancedRight.guidance,
  },
  {
    name: i18n.ts('policy.packageTargeting.typeOptions.securityUpdates'),
    value: PolicyAdvancedRight.security_updates,
  },
  {
    name: i18n.ts('policy.packageTargeting.typeOptions.servicePacks'),
    value: PolicyAdvancedRight.service_packs,
  },
  {
    name: i18n.ts('policy.packageTargeting.typeOptions.tools'),
    value: PolicyAdvancedRight.tools,
  },
  {
    name: i18n.ts('policy.packageTargeting.typeOptions.updateRollups'),
    value: PolicyAdvancedRight.update_rollups,
  },
  {
    name: i18n.ts('policy.packageTargeting.typeOptions.updates'),
    value: PolicyAdvancedRight.updates,
  },
  {
    name: i18n.ts('policy.packageTargeting.typeOptions.upgrades'),
    value: PolicyAdvancedRight.upgrades,
  },
];

export const severityOptions: RightOption[] = [
  {
    name: i18n.ts('general.severities.unknown'),
    value: PolicyAdvancedRight.other,
  },
  {
    name: i18n.ts('general.severities.none'),
    value: PolicyAdvancedRight.none,
  },
  {
    name: i18n.ts('general.severities.low'),
    value: PolicyAdvancedRight.low,
  },
  {
    name: i18n.ts('general.severities.medium'),
    value: PolicyAdvancedRight.medium,
  },
  {
    name: i18n.ts('general.severities.high'),
    value: PolicyAdvancedRight.high,
  },
  {
    name: i18n.ts('general.severities.critical'),
    value: PolicyAdvancedRight.critical,
  },
];

// Left Options with associated conditional and right options
export const advancedOptions: AdvancedOption[] = [
  {
    name: i18n.ts('policy.packageTargeting.advancedFilters.patchSource'),
    value: PolicyAdvancedLeft.patch_source,
    conditional: conditionalIsOptions,
    valueInput: new SelectValueInput(patchSourceOptions),
  },
  {
    name: i18n.ts('policy.packageTargeting.advancedFilters.patchOS'),
    value: PolicyAdvancedLeft.patch_os,
    conditional: conditionalIsOptions,
    valueInput: new SelectValueInput(patchOSOptions),
  },
  {
    name: i18n.ts('policy.packageTargeting.advancedFilters.type'),
    value: PolicyAdvancedLeft.type,
    conditional: conditionalIsOptions,
    valueInput: new SelectValueInput(typeWindowsOnlyOptions),
  },
  {
    name: i18n.ts('policy.packageTargeting.advancedFilters.displayName'),
    value: PolicyAdvancedLeft.display_name,
    conditional: conditionalContainsOptions,
    valueInput: new TextValueInput(3),
  },
  {
    name: i18n.ts('policy.packageTargeting.advancedFilters.patchSeverity'),
    value: PolicyAdvancedLeft.severity,
    conditional: conditionalComparableOptions,
    valueInput: new SelectValueInput(severityOptions),
  },
  {
    name: i18n.ts('policy.packageTargeting.advancedFilters.patchAge'),
    value: PolicyAdvancedLeft.patch_days_old,
    conditional: conditionalPatchAgeOptions,
    valueInput: new NumberValueInput(1, 180, i18n.ts('suffixes.daysOld')),
  },
];

export const getAdvancedOptionByValue = (
  optionValue: PolicyAdvancedLeft,
): AdvancedOption => {
  return advancedOptions.find(
    (item) => item.value === optionValue,
  ) as AdvancedOption;
};

const getValueInput = (vm): ValueInput | null => {
  const advancedOption = getAdvancedOptionByValue(vm.left);
  return advancedOption ? advancedOption.valueInput : null;
};

export const advancedScopeValidations = {
  advanced_filter: {
    required: requiredIf((configuration: PolicyConfiguration) => {
      return configuration.patch_rule === PolicyPatchRule.advanced;
    }),
    $each: {
      left: { required },
      condition: { required },
      right: {
        required,
        numberBetween: customValidator<NumberValueInput>(
          InputType.number,
          getValueInput,
          numberInputIsBetween,
        ),
        textLength: customValidator<TextValueInput>(
          InputType.text,
          getValueInput,
          textHasMinLength,
        ),
      },
    },
  },
};
