var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"tag":"form","fluid":""},on:{"submit":function($event){$event.preventDefault();_vm.canModifyPolicy && _vm.handleSubmit(_vm.$v)}}},[_c('v-card-text',[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('div',{staticClass:"PolicyPatchEditor tw-flex tw-items-center tw-justify-between tw-mb-2"},[_c('div',{staticClass:"tw-flex tw-items-center"},[(_vm.policyTitle)?_c('h1',{staticClass:"tw-pb-0"},[_vm._v(" "+_vm._s(_vm.isEdit ? (_vm.canModifyPolicy ? 'Edit' : '') : 'Create')+" "+_vm._s(_vm.policyTitle)+" ")]):_vm._e(),(_vm.policyTitle)?_c('policy-help',{attrs:{"type":_vm.policyTitle}}):_vm._e()],1),(
            _vm.isEdit &&
            _vm.originalPolicyType ===
              _vm.PolicyTypeQueryParams.patch_manual_approval &&
            _vm.policyType === _vm.PolicyTypeQueryParams.patch_manual_approval
          )?_c('manual-approval-button',{attrs:{"policy-id":_vm.policyForm.id,"include-optional":_vm.policyForm.configuration.include_optional}}):_vm._e()],1),_vm._t("right-header")],2),(!_vm.canModifyPolicy)?_c('ax-alert-banner',{attrs:{"id":_vm.value.id,"messagetype":"info","setback":"","dismissible":false}},[_vm._v(" "+_vm._s(_vm.$t('policy.edit.cannotEdit:notification')))]):_vm._e(),_c('div',{staticClass:"tw-flex tw-items-center tw-mb-2"},[_c('div',{staticClass:"tw-flex tw-items-center tw-mx-3"},[_c('label',{staticClass:"section-title tw-mr-3",attrs:{"for":"patch-policy-type"}},[_vm._v("Type")]),_c('ax-select',{staticClass:"mb-0",attrs:{"id":"patch-policy-type","items":_vm.PATCH_TYPES,"item-text":"name","item-value":"param","hide-details":""},on:{"change":_vm.handlePolicySelectionChange},model:{value:(_vm.policyType),callback:function ($$v) {_vm.policyType=$$v},expression:"policyType"}})],1),_c('v-divider')],1),(_vm.formReady)?_c('policy-patch-form',{attrs:{"form":_vm.policyForm,"ready":_vm.formReady,"policy-type":_vm.policyType,"all-device-groups":_vm.allDeviceGroups,"vuelidate":_vm.$v.policyForm},on:{"update-scheduler":function (val) {
          Object.assign(_vm.policyForm, val);
        }}}):_vm._e(),(_vm.formSynced && _vm.formReady && _vm.canModifyPolicy)?_c('ax-confirm-cancel',{attrs:{"submit-label":((_vm.isEdit ? 'Save' : 'Create') + " Policy"),"type":"submit"},on:{"leave":_vm.goBack},scopedSlots:_vm._u([(_vm.formSubmitted && _vm.$v.$invalid)?{key:"error-message",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('general.validations.fieldsHaveErrors'))+" ")]},proxy:true}:null,{key:"alternative-submit",fn:function(){return [(
            _vm.isEdit &&
            _vm.originalPolicyType ===
              _vm.PolicyTypeQueryParams.patch_manual_approval &&
            _vm.policyType === _vm.PolicyTypeQueryParams.patch_manual_approval
          )?_c('manual-approval-button',{attrs:{"policy-id":_vm.policyForm.id}}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.policyForm),callback:function ($$v) {_vm.policyForm=$$v},expression:"policyForm"}},[(_vm.canDeletePolicy && _vm.isEdit)?_c('v-col',{attrs:{"cols":"auto"}},[_c('ax-button',{attrs:{"mode":"secondary"},on:{"click":function($event){return _vm.$emit('remove-policy')}}},[_vm._v("Remove Policy")])],1):_vm._e()],1):_vm._e()],1),_c('ax-modal',{model:{value:(_vm.modals.noSchedule.opened),callback:function ($$v) {_vm.$set(_vm.modals.noSchedule, "opened", $$v)},expression:"modals.noSchedule.opened"}},[_c('ax-no-schedule-modal',{on:{"close-modal":_vm.modals.noSchedule.close,"save-policy":function($event){return _vm.handleSubmit(_vm.$v, true)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }