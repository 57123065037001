import { render, staticRenderFns } from "./PatchPackageScope.vue?vue&type=template&id=a4b85942&scoped=true&"
import script from "./PatchPackageScope.vue?vue&type=script&lang=ts&"
export * from "./PatchPackageScope.vue?vue&type=script&lang=ts&"
import style0 from "./PatchPackageScope.vue?vue&type=style&index=0&id=a4b85942&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4b85942",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardText,VCardTitle,VCol,VIcon,VRow})
