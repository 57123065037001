import { Ref, shallowRef, watch } from '@vue/composition-api';
import { QueryStatusErrorFromQuery } from '@ax/cache-and-dedupe-vue';
import VueRouter, { Route } from 'vue-router';
import * as SoftwareService from '@ax/data-services-software';
import { showStandardHttpErrorNotification } from '@ax/notifications';
import { TableHeader } from '@/types/table-options';

import { DEFAULT_TABLE_PAGINATION } from '@/utils/constants';
import { QueryStateStorageConfig } from '@/utils/storage';
import { DataTableState } from '@/utils/data-table-state';
import { formatPolicyPackageSearch } from '@/utils/policies';

export const policySoftwareHeaders: TableHeader[] = [
  {
    text: 'Package List',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: false,
    value: 'display_name',
  },
];

function showErrorNotifications(
  error: Ref<
    QueryStatusErrorFromQuery<SoftwareService.GetPolicySoftware> | undefined
  >,
) {
  watch(error, (newError) => {
    if (newError !== undefined) {
      showStandardHttpErrorNotification(
        newError.type === 'ApiClientError' ? newError.error : undefined,
      );
    }
  });
}

export interface UsePolicySoftwareProps {
  readonly router: VueRouter;
  readonly route: Route;
  readonly orgId: Ref<number>;
}

export function usePolicySoftwareTableState({
  router,
  route,
  orgId,
}: UsePolicySoftwareProps) {
  const currentQuery = shallowRef<string>();
  const search = shallowRef('');

  const automoxSupported = shallowRef(false);

  const { error, ...remainingResponse } =
    SoftwareService.usePolicySoftware(currentQuery);

  showErrorNotifications(error);

  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-policy-software-prefs',
    store: localStorage,
  };

  const { tableState, queryState, updateQueryState } =
    DataTableState.synchronize(
      route,
      router,
      {
        o: { defaultValue: orgId.value },
        pid: { defaultValue: undefined, excludeFromApiUrl: true },
        page: {
          defaultValue: 1,
          legacyUiValueTranslations: new Map().set(0, 1),
          apiKeyTranslation: 'p',
        },
        limit: {
          defaultValue: DEFAULT_TABLE_PAGINATION,
          storeInBrowser: true,
          apiKeyTranslation: 'l',
        },
        sort_dir: {
          defaultValue: 'desc',
          // if a user has bookmarked a query with &sort=direction, treat it as if it were sort_dir,
          // which is the new standard
          legacyUiKeyTranslation: 'sort',
        },
        sort_by: {
          defaultValue: 'display_name',
        },
        frompage: { defaultValue: undefined, excludeFromApiUrl: true },
        is_managed: {
          defaultValue: undefined,
          apiValueTranslations: new Map().set(true, 1).set(false, undefined),
        },
        name: { defaultValue: undefined },
      },
      {
        storageConfig,
        callback: ({ apiQuery }) => {
          if (apiQuery) {
            currentQuery.value = apiQuery;
          }
        },
        onBeforeApiUrlGeneration: (state) => {
          if (state.name) {
            return { ...state, name: formatPolicyPackageSearch(state.name) };
          }
          return state;
        },
      },
    );

  return {
    automoxSupported,
    search,
    tableState,
    queryState,
    updateQueryState,
    ...remainingResponse,
  };
}
